<template>
  <ClubTemplate :clubId="clubId">
    <div class="container">

      <div class="page-header">
        <div class="page-header__ell">
          <h1 class="page-title">Редактировать помещение</h1>
        </div>
      </div>

      <ClubRoomAddForm
        v-if="formData"
        ref="ClubRoomAddForm"
        :form-data="formData"

        @removeImage="removeImage"
      />

      <form class="form">
        <div class="btn-gp">
          <b-button size="lg" variant="danger" @click="save">Сохранить</b-button>
          <router-link :to="{name: this.$routeName.CLUB_ROOM_LIST}" class="btn btn-link btn-lg">Отмена</router-link>
        </div>
      </form>

    </div>
  </ClubTemplate>
</template>

<script>
import ClubTemplate from './_component/ClubTemplate.vue';
import ClubRoomAddForm from "@component/Form/ClubRoomAddForm";

export default {
  components: {
    ClubTemplate,
    ClubRoomAddForm
  },
  props: {
    clubId: {
      type: [String, Number],
      required: true
    },
    roomId: {
      type: [String, Number],
      required: true
    },
  },
  data() {
    return {
      formData: false,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load(){
      RequestManager.Club.getClubRoomById({ clubId: this.clubId, roomId: this.roomId }).then((res) => {
        this.formData = Object.assign({}, res);
        this.formData.street = this.formData.street ? 'STREET' : 'ROOM';
      });
    },

    removeImage(imageObj){
      RequestManager.Club.deleteClubRoomMedia({
        clubId: this.clubId,
        roomId: this.roomId,
        fileId: imageObj.getId(),
      });
    },

    async save() {
      let formData = this.$refs.ClubRoomAddForm.formSubmitGetData();
      if(!formData) { return; }

      let sendData = Object.assign({}, formData);
      sendData.tags = sendData.tags.map(t => ({ tag: t.tag }));
      delete sendData.courtmedia_set;

      sendData.street = (sendData.street === 'STREET');
      
      try {
        const res = await RequestManager.Club.updateClubRoom({
          clubId: this.clubId,
          roomId: this.roomId,
          room: sendData
        });

        const promiseSend = [];
        let fix = Promise.resolve();
        promiseSend.push(fix);
        for (const photo of formData.courtmedia_set) {
          if (!photo.getFile().name) {
            continue;
          }
          let media = RequestManager.Club.addClubRoomMedia({
            clubId: this.clubId,
            roomId: res.id,
            postData: { media: photo }
          });
          promiseSend.push(media);
        }
        Promise.all(promiseSend).then(() => {
          this.$toasts.push({
            message: 'Помещение клуба успешно обновлено',
            duration: 4000,
          });
          this.$router.push({ name: this.$routeName.CLUB_ROOM_LIST });
        });
      } catch(e) {
        console.error(e);
      }
    }
  }
};
</script>
